import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  timeGroup: {
    display: "flex"
  },
  timeUnit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "55px",
    textAlign: "center",
    padding: theme.spacing(1)
  },
  paperTimer: {
    padding: theme.spacing(1)
  }
});

function TimerDisplay(props) {
  const { classes, targetTime, hours, minutes, title } = props;
  return (
    <Grid item xs={12} sm={4}>
      <Paper className={classes.paperTimer}>
        <Typography variant="body1">{title}</Typography>
        <div className={classes.timeGroup}>
          <div className={classes.timeUnit}>
            <Typography variant="h3">{hours}</Typography>
            <Typography variant="caption">Hours</Typography>
          </div>
          <div className={classes.timeUnit}>
            <Typography variant="h3">{minutes}</Typography>
            <Typography variant="caption">Minutes</Typography>
          </div>
        </div>
        <Typography variant="body1">
          Cooking will start at {targetTime}
        </Typography>
      </Paper>
    </Grid>
  );
}

export default withStyles(styles)(TimerDisplay);
