import React, { Component } from "react";
import moment from "moment";
import ReactGA from 'react-ga';

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";

import TimerDisplay from "./TimerDisplay"

ReactGA.initialize('UA-125150546-5');
ReactGA.set({'appName': 'PotTimer', 'appVersion': process.env.REACT_APP_VERSION});
ReactGA.pageview('/')


const styles = theme => ({
  root: {
    flexGrow: 1
  },
  rootGrid: {
    padding: "20px"
  },
  gridItem: {
    display: "flex",
    alignItems: "center"
  },
  timeGroup: {
    display: "flex"
  },
  timeUnit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "55px",
    textAlign: "center",
    padding: theme.spacing(1)
  },
  hiddenGridItem: {
    display: "none"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1)
  },
  paperTimer: {
    padding: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hideButton: {
    margin: theme.spacing(1)
  },
  title: {
    flexGrow: 1
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
});

const BREAKFAST_TIME = "07:00";
const DINNER_TIME = "18:00";

const BREAKFAST = "Breakfast";
const DINNER = "Dinner";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: moment(),
      finishTime: BREAKFAST_TIME,
      selected: BREAKFAST,
      infoHidden: false
    };
    this.buttonClick = this.buttonClick.bind(this);
    this.changeTime = this.changeTime.bind(this);
    this.buttonHide = this.buttonHide.bind(this);
  }
  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ currentTime: moment() }),
      1000
    );
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  buttonClick(e) {
    if (e.currentTarget.textContent === BREAKFAST) {
      this.setState({ selected: BREAKFAST, finishTime: BREAKFAST_TIME });
    } else {
      this.setState({ selected: DINNER, finishTime: DINNER_TIME });
    }
  }
  buttonHide() {
    this.setState({ infoHidden: true });
  }
  changeTime(e) {
    this.setState({
      finishTime: e.currentTarget.value
    });
  }

  calculateDifference() {
    const { currentTime, finishTime } = this.state;
    const finishMoment = moment(finishTime, "HH:mm");
    if (finishMoment < currentTime) {
      finishMoment.add(1, "d");
    }
    const duration = moment.duration(finishMoment.diff(currentTime));
    const hours = duration.hours();
    const minutes = duration.minutes();
    let hoursDown = 0;
    let hoursUp = 0;
    let minutesDown = 0;
    let minutesUp = 0;
    if (minutes < 20) {
      hoursDown = hours;
      hoursUp = hours;
      minutesDown = "00";
      minutesUp = 20;
    } else if (minutes < 40) {
      hoursDown = hours;
      hoursUp = hours;
      minutesDown = 20;
      minutesUp = 40;
    } else {
      hoursDown = hours;
      hoursUp = hours + 1;
      minutesDown = 40;
      minutesUp = "00";
    }
    const timerDown = `${hoursDown}:${minutesDown}`;
    const startTimeDown = moment(currentTime)
      .add(hoursDown, "h")
      .add(minutesDown, "m");
    const timerUp = `${hoursUp}:${minutesUp}`;
    const startTimeUp = moment(currentTime)
      .add(hoursUp, "h")
      .add(minutesUp, "m");
    const timer = {
      timerUp: timerUp,
      startTimeUp: startTimeUp,
      timerDown: timerDown,
      hoursDown: hoursDown,
      minutesDown: minutesDown,
      hoursUp: hoursUp,
      minutesUp: minutesUp,
      startTimeDown: startTimeDown
    };
    return timer;
  }

  render() {
    const { classes } = this.props;
    const timer = this.calculateDifference();
    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Pot Timer - Delay Start Calculator
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid className={classes.rootGrid} container spacing={3}>
          <Grid
            item
            className={
              this.state.infoHidden ? classes.hiddenGridItem : classes.gridItem
            }
            xs={12}
          >
            <Paper className={classes.paper}>
              <Typography variant="body1">
                A calculator to help set your Instant Pot delay start timer.
                Fill in the time you want the Instant Pot to start cooking and
                it will give you the timer value.
              </Typography>
              <Button
                className={classes.hideButton}
                variant="contained"
                onClick={this.buttonHide}
              >
                Hide
              </Button>
            </Paper>
          </Grid>
          <Grid item className={classes.gridItem} xs={12}>
            <ButtonGroup size="small">
              <Button
                onClick={this.buttonClick}
                disabled={this.state.selected === BREAKFAST}
              >
                {BREAKFAST}
              </Button>
              <Button
                onClick={this.buttonClick}
                disabled={this.state.selected === DINNER}
              >
                {DINNER}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item className={classes.gridItem} xs={12}>
            <TextField
              id="time"
              label="Cooking start time:"
              type="time"
              value={this.state.finishTime}
              className={classes.textField}
              onChange={this.changeTime}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 300 // 5 min
              }}
            />
          </Grid>          
          <TimerDisplay             
            targetTime={timer.startTimeDown.format("hh:mm A")} 
            title="Minimum Delay"
            hours={timer.hoursDown}
            minutes={timer.minutesDown}
          />
          <TimerDisplay             
            title="Maximum Delay"
            targetTime={timer.startTimeUp.format("hh:mm A")} 
            hours={timer.hoursUp}
            minutes={timer.minutesUp}
          />
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(App);
